@import '../../assets/_variables.scss';

.plan-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  
    .popup-content {
      background-color: #ffffff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: auto;
      width: 80%; // Ajusta según sea necesario
      max-width: 800px; // Limita el ancho máximo para pantallas más grandes
      max-height: 800px;
  
      h2 {
        margin-bottom: 15px;
      }
  
      .plans {
        display: flex;
        flex-direction: row; // Asegura que los planes se muestren en línea
        align-items: stretch; // Ajusta la alineación de los elementos hijos
        justify-content: space-around; // Distribuye espacio equitativamente
        flex-wrap: wrap; // Permite que los elementos se envuelvan si no hay suficiente espacio
        gap: 20px; // Espacio entre las tarjetas
        width: 100%; // Hace que el contenedor ocupe todo el ancho disponible
        overflow: auto;
  
        .card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 15px;
            text-align: center;
            transition: box-shadow 0.2s ease;
            width: 200px;
            height: 250px;
          
            &:hover {
              box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
            }
          
            &.selected {
              border-color: #007bff;
              background-color: rgba(0, 123, 255, 0.1);
            }
          
            h3 {
              margin: 0 0 10px;
            }
          
            ul {
              list-style: none;
              padding: 0;
              margin: 0 0 10px;
            }
          
            p {
              margin: 5px 0;
            }
          
            a {
              text-decoration: none;
              padding: 10px 20px;
              border: none;
              color: #fff;
              cursor: pointer;
              border-radius: 5px;
              transition: background-color 0.2s ease;
              border: 1px solid #0056b3;
              &:hover {
                background-color: #0056b3;
                color: #fff;
              }
            }
          }
          
      }
  
      .btn {
        margin-top: 20px;
        padding: 10px 20px;
        border: none;
        background-color: #007bff;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.2s ease;
  
        &:hover {
          background-color: #0056b3;
        }
      }
      
    }
  }

  
  