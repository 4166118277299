@import '../../assets/_variables.scss';

.change-plan-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px; // Más espacio alrededor
    font-family: Arial, sans-serif;
    background-color: #f9f9f9; // Fondo claro
    color: #333; // Texto oscuro

    .page-title {
        font-size: 2.5rem; // Aumentar el tamaño
        margin-bottom: 30px; // Más espacio debajo
        color: $colorNegro; // Color primario
    }

    .plan-details {
        display: flex;
        align-items: flex-start; // Alinear el texto hacia arriba
        justify-content: space-around;
        width: 100%;
        max-width: 900px; // Aumentar el ancho máximo
        margin-bottom: 30px;

        .current-plan, .new-plan {
            flex: 1;
            margin: 0 20px; // Más espacio entre planes
            padding: 30px; // Más espacio interno
            background-color: #fff; // Fondo blanco para las tarjetas
            border-radius: 12px; // Bordes más redondeados
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); // Sombra más profunda

            h2 {
                color: $colorNegro; // Títulos en color primario
                margin-bottom: 15px; // Espacio debajo del título
            }

            ul {
                list-style-type: none; // Quitar los puntos de la lista
                padding: 0;

                li {
                    margin: 8px 0; // Espacio entre los elementos de la lista
                }
            }
        }

        .migration-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 3rem; // Aumentar el tamaño de la flecha
            margin: 0 20px; // Espacio alrededor de la flecha
            color: $colorNegro; // Color primario
        }
    }

    .billing-info {
        width: 100%;
        max-width: 600px; // Ancho máximo del formulario
        background-color: #ffffff; // Fondo blanco
        padding: 20px; // Espacio interno
        border-radius: 12px; // Bordes redondeados
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); // Sombra

        .form-group {
            display: flex;
            flex-direction: column;
            gap: 20px; // Espacio entre los campos del formulario
            margin-bottom: 30px;

            .MuiTextField-root {
                width: 100%;
            }
        }

        .MuiFormControl-root {
            margin-bottom: 15px; // Espacio inferior para FormControl
        }
    }

    .clarification-message {
        margin: 15px 0; // Espacio alrededor del mensaje de aclaración
        color: $colorNegro; // Color para el mensaje de aclaración
        font-style: italic; // Cursiva para el mensaje de aclaración
        text-align: center; // Centrar el texto
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 600px;
        margin: 10px;

        button {
            flex: 1;
            margin: 0 10px; // Espacio alrededor de los botones
            padding: 15px; // Más espacio interno
            border: none;
            border-radius: 6px; // Bordes más redondeados
            font-size: 1.1rem; // Aumentar el tamaño de la fuente
            cursor: pointer;
            transition: background-color 0.3s ease;

            &.confirm-button {
                background-color: #007bff; // Color primario
                color: #fff;

                &:hover {
                    background-color: #0056b3; // Color más oscuro al pasar el ratón
                }
            }

            &.cancel-button {
                background-color: #f44336; // Rojo para cancelar
                color: #fff;

                &:hover {
                    background-color: #d32f2f; // Color más oscuro al pasar el ratón
                }
            }
        }
    }
}
