@import '../../assets/_variables.scss';

.button-container {
    display: flex;
    justify-content: center; /* Centra el botón horizontalmente */
    align-items: center; /* Opcional: centra el botón verticalmente si es necesario */

    .btn-graph-button {
        background-color: $colorCeleste;
        color: white; // Color del texto
        font-size: 16px; // Tamaño de la fuente
        font-weight: bold; // Para que el texto tenga presencia
        text-align: center; // Centra el texto
        text-transform: uppercase; // Convierte el texto a mayúsculas
        padding: 16px; // Espaciado interno para que sea cuadrado
        border: none; // Sin bordes
        border-radius: 5px; // Esquinas cuadradas
        width: 100px; // Anchura fija para mantener proporción
        height: 100px; // Altura igual a la anchura para hacer un cuadrado
        cursor: pointer; // Cambia el cursor a la mano
        transition: all 0.2s ease-in-out; // Transición suave para el hover y clic
        display: inline-block; // Para mantener la forma de botón
        position: relative; // Para controlar los pseudo-elementos
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Sombra sutil para darle profundidad
    
        &:hover {
            background-color: darken($colorAzul, 10%); // Cambia a un azul más oscuro
            transform: translateY(-2px); // Se "levanta" ligeramente
        }
    
        &:active {
            transform: translateY(2px); // Se "presiona" hacia abajo
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Reduce la sombra para dar la sensación de presionado
        }
    
        &:focus {
            outline: none; // Elimina el borde de enfoque predeterminado
            box-shadow: 0 0 0 3px lighten($colorAzul, 40%); // Resalta el botón con un borde claro
        }
    }
}

