@import '../../assets/_variables.scss';

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.register-box {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 200px;
}

.plan-type {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.progress-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  transition: background-color 0.3s ease;
}

.circle.active {
  background-color: #3498db;
  /* Color azul para los pasos activos */
}

.register-form, .register-form-1 {
  display: flex;
  flex-direction: column;
}

.register-form input,
.register-form select {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-navigation {
  display: flex;
  justify-content: space-between; /* Esto mantiene los botones a los lados */
  margin-top: 20px;
}

.submit-btn,
.back-btn {
  width: 48%; /* Ambos botones ocuparán el 48% del ancho del contenedor */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
}

.submit-btn {
  background-color: #3498db;
  color: white;
}

.back-btn {
  background-color: #95a5a6;
  color: white;
}

.payment-fields {
  display: flex;
  flex-direction: column;

  input {
    margin-top: 10px;
  }
}

.summary-section {
  display: flex;
  flex-direction: column;
}

.summary-content {
  display: flex;
  justify-content: space-between; /* Distribuye el espacio entre la izquierda y la derecha */
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.summary-left, .summary-right {
  min-width: 300px; /* Para que ambos divs ocupen la mitad del espacio aproximadamente */
}

.summary-left {
  text-align: left;
  margin: 10px;
  h4 {
    margin-top: 15px;
    margin-bottom: 10px;
    color: #3498db;
  }
}

.payment-details, .subscription-details {
  margin: 10px;
  h4 {
    margin-top: 15px;
    margin-bottom: 10px;
    color: #3498db;
  }
}

.payment-details {
  text-align: left;
}

.subscription-details {
  text-align: left;
}