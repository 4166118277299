@import '../../assets/_variables.scss';

.settings-page {
  display: flex;
  flex-direction: column;
  padding: 20px;

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: $colorNegro;
    margin-bottom: 20px;
  }

  .settings-card {
    background-color: $colorBlanco;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid $colorGris;

    h3 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 15px;
      color: $colorNegro;
    }

    .info-item {
      display: flex;
      column-gap: 20px;
      align-items: center;
      margin-bottom: 15px;
      flex-wrap: wrap;

      span {
        font-weight: 600;
        color: $colorNegro;
      }

      .info-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
        
        p {
          font-weight: 300;
          color: $colorGris;
          margin: 0;
        }

        .actions {
          display: flex;
          gap: 10px;

          .btn {
            background-color: $colorCeleste;
            border: none;
            border-radius: 4px;
            padding: 8px 12px;
            font-size: 14px;
            color: $colorBlanco;
            cursor: pointer;
            transition: background-color 0.2s ease;

            &:hover {
              background-color: darken($colorCeleste, 10%);
            }
          }
        }
      }
    }
    .personal{
      flex-direction: column;
      align-items: flex-start;
    }

    .copy-api-key {
      color: $colorCeleste;
      cursor: pointer;
      font-weight: 500;
      transition: color 0.2s ease;

      &:hover {
        color: darken($colorCeleste, 10%);
      }
    }
  }

  .loading,
  .error {
    font-size: 18px;
    color: $colorNegro;
  }

  .delete-account {
    display: flex;
    margin-top: 20px;

    .delete-btn {
      background-color: $colorRojo; // Define este color en tu archivo de variables
      color: $colorBlanco;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: darken($colorRojo, 10%);
      }
    }
  }
}
