@import '../../assets/_variables.scss';

.widget {
    padding: 10px;
    background-color: $colorBlanco;
    border-radius: 5px;
    border: 1px solid $colorNegro25;
    margin: 5px;
    color: $colorNegro;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.25);
    transition: all 0.2s ease;
    min-width: 150px;

    &:hover {
        border: 1px solid $colorNegro;
    }

    &:active {  
        cursor: grabbing;
    }

    .widget-header {
        display: flex;
        justify-content: space-between;

        .edit-button {
            background-color: #6c757d;
            color: white;
            border: none;
            border-radius: 5px;
            padding: 8px;
            cursor: pointer;
            font-size: 12px;
            transition: background-color 0.3s, transform 0.2s;
            display: flex;
            align-items: center;
            height: 40px;

            &:hover {
                background-color: #0056b3;
                transform: scale(1.05);
            }

            &:focus {
                outline: none;
                box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
            }
        }
    }

    .widget-content {
        margin-top: 10px;
    }

    .widget-edit-form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
            font-weight: bold;
            font-size: 14px;
        }

        input[type="text"],
        input[type="number"],
        select {
            padding: 5px;
            border: 1px solid $colorNegro25;
            border-radius: 5px;
        }

        .alerts-section {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        .topic-container{
            .topic{
                font-size: 12px;
                background-color: $colorCelesteClaro;
                padding: 10px;
                border-radius: 10px;
                margin-top: 5px;
            }
        }

        .edit-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;

            button {
                margin: 10px;
                padding: 8px 12px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-size: 14px;
                transition: background-color 0.3s;

                &.btn-save {
                    background-color: #28a745;
                    color: white;

                    &:hover {
                        background-color: #218838;
                    }
                }

                &.btn-cancel {
                    background-color: #dc3545;
                    color: white;

                    &:hover {
                        background-color: #c82333;
                    }
                }

                &.btn-delete {
                    background-color: #dc3545;
                    color: white;

                    &:hover {
                        background-color: #c82333;
                    }
                }
            }
        }
    }
}

.widgets.editing {
    cursor: grab;
}

.widgets.editing .widget {
    cursor: grab;
}

.widgets.editing .widget:active {
    cursor: grabbing;
}
