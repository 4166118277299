@import '../../assets/_variables.scss';

.background-popup {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    .popup-container {
        background-color: $colorBlanco;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        width: 500px;
        max-width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 200px;

        .widget-title {
            width: 100%;
            margin-bottom: 20px;

            label {
                font-size: 16px;
                margin-bottom: 5px;
                display: block;
            }

            input {
                padding: 8px;
                border-radius: 5px;
                border: 1px solid $colorNegro25;
                font-size: 16px;
            }
        }
        
        h2 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        hr {
            width: 100%;
            border: none;
            height: 1px;
            background-color: $colorNegro25;
            margin-bottom: 20px;
        }

        .graph-type-list {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;
            overflow: auto;

            .graph-type {
                min-width: 150px;
                border-radius: 10px;
                padding: 10px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                transition: box-shadow 0.3s ease;
                border: 1px solid $colorNegro25;
                flex: 1;
                margin: 0 5px;

                &.active {
                    border: 2px solid $colorCeleste;
                }

                img {
                    width: 80px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                }
            }
        }

        .item-variable-list {
            width: 100%;
            margin-bottom: 20px;

            label {
                margin-bottom: 5px;
                font-size: 16px;
            }

            select {
                width: 100%;
                padding: 8px;
                border-radius: 5px;
                border: 1px solid $colorNegro25;
                margin-bottom: 15px;
            }
            .button-options-container{
                display: flex;
                flex-direction: column;
                input{
                    border: 1px solid $colorNegro25;;
                }
                .topic-container{

                    .topic{
                        font-size: 12px;
                        background-color: $colorCelesteClaro;
                        padding: 10px;
                        border-radius: 10px;
                        margin-top: 5px;
                    }
                }
                .description-topic{
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
            .switch-options-container{
                display: flex;
                flex-direction: column;
                input{
                    border: 1px solid $colorNegro25;;
                }
                p{
                    margin: 5px 0;
                }
                .topic-container{

                    .topic{
                        font-size: 12px;
                        background-color: $colorCelesteClaro;
                        padding: 10px;
                        border-radius: 10px;
                        margin-top: 5px;
                    }
                }
                .description-topic{
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
            .list-options-container{
                display: flex;
                flex-direction: column;
                .list-option{
                    display: flex;
                    width: 100%;
                    column-gap: 5px;
                    align-items: center;
                    input{
                        width: 100%;
                        border: 1px solid $colorNegro25;;
                    }
                    .btn-remove-option{
                        color: $colorBlanco;
                        padding: 0px 10px;
                        width: 30px;
                        height: 30px;
                        background-color: $colorRojo;
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                        &:hover {
                            background-color: darken($colorRojo, 10%);
                        }
                    }
                }
                .btn-add-option{
                    padding: 10px;
                    background-color: $colorBlanco;
                    color: $colorNegro;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color 0.3s;
                    border: 1px solid $colorNegro25;
                    &:hover {
                        background-color: darken($colorNegro, 10%);
                        color: $colorBlanco;
                    }
                }
                .topic-container{

                    .topic{
                        font-size: 12px;
                        background-color: $colorCelesteClaro;
                        padding: 10px;
                        border-radius: 10px;
                        margin-top: 5px;
                    }
                }
                .description-topic{
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
            .slider-options-container{
                display: flex;
                flex-direction: column;
                input{
                    border: 1px solid $colorNegro25;;
                }
                p{
                    margin: 5px 0;
                }
                .topic-container{

                    .topic{
                        font-size: 12px;
                        background-color: $colorCelesteClaro;
                        padding: 10px;
                        border-radius: 10px;
                        margin-top: 5px;
                    }
                }
                .description-topic{
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
        }

        .alert-settings {
            width: 100%;
            margin-bottom: 20px;

            .alert-input {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                label {
                    font-size: 16px;
                }

                input[type="number"] {
                    width: 150px;
                    padding: 8px;
                    border-radius: 5px;
                    border: 1px solid $colorNegro25;
                }
            }
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 24px;

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $colorNegro25;
                transition: .4s;
                border-radius: 24px;

                &::before {
                    position: absolute;
                    content: "";
                    height: 18px;
                    width: 18px;
                    left: 3px;
                    bottom: 3px;
                    background-color: white;
                    transition: .4s;
                    border-radius: 50%;
                }
            }

            input:checked + .slider {
                background-color: $colorCeleste;
            }

            input:checked + .slider::before {
                transform: translateX(26px);
            }
        }

        .button-group {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .btn-popup {
                flex: 1;
                margin: 0 5px;
                padding: 10px;
                background-color: $colorCeleste;
                color: $colorBlanco;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: darken($colorCeleste, 10%);
                }
            }
        }
    }
}
