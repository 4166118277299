@import '../../assets/_variables.scss';

.devices-page {
    padding: 20px;
  }
  
  .devices-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .device {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .device-data {
    margin-top: 10px;
  }
  
  .device-variables {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
  }
  
  .device-variable {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 12px;
  }
  
  .change-name-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  