@import '../../assets/_variables.scss';

.title{
    margin-left: 15px;
    color: $colorBlanco;
    font-weight: normal;
    font-size: 20px;
    span{
        font-weight: 900;
        color: $colorCeleste;
    }
}