@import '../../assets/_variables.scss';

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fondo negro semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-add-device {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001; /* Asegura que esté por encima del overlay */
}

.popup-add-device h3 {
    margin-bottom: 20px;
    color: $colorNegro;
}

.popup-add-device input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.popup-buttons {
    display: flex;
    justify-content: space-between;
}

.btn-add {
    background-color: $colorCeleste;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 48%;
}

.btn-cancel {
    background-color: $colorRojo;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 48%;
}
