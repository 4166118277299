@import '../../assets/_variables.scss';

.logo{
    margin: 15px;
    color: $colorNegro;
    font-weight: normal;
    font-size: 80px;
    span{
        font-weight: 900;
        color: $colorCeleste;
    }
}