@import '../../assets/_variables.scss';

.homepage {
    color: #333;
  
    .header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background-color: white;
      flex-wrap: wrap;
      align-items: center;
  
      .logo{
          margin-left: 15px;
          color: $colorNegro;
          font-weight: normal;
          font-size: 50px;
          span{
              font-weight: 900;
              color: $colorCeleste;
          }
      }

      .nav-links{
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
      }
  
      .nav-links a {
        margin-left: 20px;
        color: #333;
        text-decoration: none;
        font-weight: 500;
      }
    }
  
    .hero {
      display: flex;
      justify-content: space-between;
      padding: 50px;
      background-color: #fff;
      flex-wrap: wrap;
  
      .hero-text {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 300;
      }
  
      img {
        max-width: 50vw;
      }
    }

    .steps-section {
      padding: 2rem;
      background-color: #f9f9f9;
      border-radius: 8px;
      margin-bottom: 2rem;
    
      h2 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        color: #333;
      }
    
      .steps-home {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    
        .step-home {
          background-color: #fff;
          padding: 1.5rem;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
          h3 {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
            color: #007bff;
          }
    
          p {
            font-size: 1rem;
            color: #666;
            margin: 0;
          }
        }
      }
    }
  
    .features {
      padding: 50px;
      text-align: center;
  
      h2 {
        font-size: 32px;
        margin-bottom: 20px;
      }
  
      p {
        font-size: 18px;
        color: #666;
        max-width: 800px;
        margin: 0 auto;
      }
    }
  
    .pricing {
      background-color: #fff;
      padding: 50px;
      text-align: center;
  
      h2 {
        font-size: 32px;
        margin-bottom: 20px;
      }
  
      .pricing-cards {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
  
        .card {
          background-color: #fff;
          padding: 20px;
          border: 1px solid $colorNegro;
          border-radius: 8px;
          width: 300px;
          text-align: center;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
          h3 {
            font-size: 24px;
            margin-bottom: 10px;
          }
  
          ul {
            list-style: none;
            padding: 0;
            margin-bottom: 20px;
            text-align: left;
  
            li {
              margin-bottom: 10px;
              font-size: 16px;
            }
          }
  
          p {
            font-size: 20px;
            margin-bottom: 10px;
          }
  
          button {
            background-color: #1a73e8;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
  
    .faq {
      padding: 50px;
      background-color: #fff;
    }
  
    .footer {
      background-color: #333;
      color: white;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    
      .logo-footer {
        margin-left: 15px;
        color: $colorBlanco;
        font-weight: normal;
        font-size: 50px;
        span {
          font-weight: 900;
          color: $colorCeleste;
        }
      }
    
      .contact {
        font-size: 16px;
      }
    
      .terms {
        margin-top: 10px;
        a {
          color: $colorCeleste; /* Cambiar el color si es necesario */
          text-decoration: none;
          font-size: 16px;
        }
    
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }