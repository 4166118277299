

.forgot-password {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    h2 {
      margin-bottom: 10px;
      color: #333;
    }
  
    p {
      color: #666;
      margin-bottom: 20px;
    }
  
    input {
      width: 350px;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
    }
  
    .btn-send-email {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  
      &:disabled {
        background-color: #bbb;
        cursor: not-allowed;
      }
    }
  }
  