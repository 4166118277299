@import '../../assets/_variables.scss';

.register-page {
    text-align: center;
    padding: 20px;
  
    .title{
        margin-left: 15px;
        color: $colorNegro;
        font-weight: normal;
        font-size: 80px;
        span{
            font-weight: 900;
            color: $colorCeleste;
        }
    }
  
    h2 {
      font-size: 1.8em;
      margin-bottom: 20px;
    }
  
    .plans {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
  
        .card {
            background-color: #fff;
            padding: 20px;
            border: 1px solid $colorNegro;
            border-radius: 8px;
            width: 300px;
            text-align: center;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            h3 {
                font-size: 24px;
                margin-bottom: 10px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 20px;
                text-align: left;

                li {
                    margin-bottom: 10px;
                    font-size: 16px;
                }
            }

            p {
                font-size: 20px;
                margin-bottom: 10px;
            }

            a {
                text-decoration: none;
                background-color: #1a73e8;
                color: white;
                padding: 10px 20px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }
  
    .plan-card {
      border: 1px solid #ccc;
      padding: 20px;
      border-radius: 10px;
      width: 200px;
      
      h3 {
        font-size: 1.2em;
        margin-bottom: 10px;
      }
  
      p {
        margin: 5px 0;
      }
    }
  }
  