@import '../../assets/_variables.scss';

.graphnumber{
    margin: 20px;
    min-width: 200px;
    &-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        .data-container{
            display: flex;
            p{
                font-size: 70px;
                font-weight: 700;
                color: $colorNegro;
            }
        }
        .data-timestamp{
            font-size: 16px;
            color: $colorNegro25;
        }
    }
}

@media (max-width: 700px) {
    
}