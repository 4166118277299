@import '../../assets/_variables.scss';

.login-background{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: $colorBlanco;
    width: 100%;
    height: 100%;
    z-index: 20;
    .login-container{
        display: flex;
        flex-direction: column;
        .title{
            margin-left: 15px;
            color: $colorNegro;
            font-weight: normal;
            font-size: 80px;
            span{
                font-weight: 900;
                color: $colorCeleste;
            }
        }
        .login-form{
            display: flex;
            flex-direction: column;
            width: 300px;
            input{
                padding: 15px;
                border: none;
                border-radius: 10px;
                border: 1px solid $colorNegro;
                background-color: $colorBlanco;
                margin: 5px 0;
                color: $colorNegro;
                font-size: 15px;
            }
            p{
                text-align: center;
                margin: 5px 0;
                color: $colorNegro;
                .register-text{
                    color: $colorRojo;
                    font-weight: bolder;
                    cursor: pointer;
                    text-decoration: none;
                    transition: 0.2s all ease;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .login-form-forgot{
                text-align: center;
                margin: 5px 0;
                color: $colorNegro;
                cursor: pointer;
                transition: 0.2s all ease;
                text-decoration: none;
                &:hover{
                    font-weight: bolder;
                    text-decoration: underline;
                }
            }
            .wrong-credentials-text{
                text-align: center;
                margin: 5px 0;
                color: $colorRojo;
            }
            .login-form-btn{
                padding: 10px 15px;
                border-radius: 10px;
                font-size: 20px;
                margin: 5px 0;
                margin-top: 15px;
                cursor: pointer;
                color: $colorBlanco;
                background-color: $colorCeleste;
                border: none;
                &:hover{
                    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}