@import '../../assets/_variables.scss';

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .device-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 300px; /* Altura máxima para la lista */
    overflow-y: auto; /* Permitir scroll si hay muchos devices */
  }
  
  .device-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
  }
  
  .device-label {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  
  .device-details {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .device-team, .device-type {
    font-size: 14px;
    color: #666;
  }
  
  .delete-button {
    background-color: #d9534f;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .cancel-button {
    background-color: #f0ad4e;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  