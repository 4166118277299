@import '../../assets/_variables.scss';

.device {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 300px;
    transition: transform 0.2s; // Añadido para la animación de hover
    &:hover {
        transform: translateY(-3px); // Efecto al pasar el mouse
    }
}

.device h3 {
    color: $colorNegro;
    margin-bottom: 10px;
    font-size: 1.6em; // Aumentar el tamaño del texto
    text-align: center; // Centrar el texto
}

.device-data {
    margin-bottom: 15px; // Aumentar el margen inferior
}

.device-variables {
    display: flex;
    flex-wrap: wrap;
    gap: 12px; // Aumentar el espacio entre variables
    justify-content: center;
}

.device-variable {
    background-color: $colorNegro;
    color: white;
    padding: 6px 12px; // Aumentar el padding
    border-radius: 12px;
    font-size: 0.9em; // Ajustar el tamaño de fuente
}

.edit-name-container {
    display: flex; // Añadido para la alineación
    flex-direction: column;
    width: 100%; // Asegurarse de que ocupe todo el ancho
}

input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $colorCeleste; // Añadido borde
    margin-bottom: 10px; // Espacio entre el input y los botones
    font-size: 1em; // Tamaño de fuente
}

.save-button,
.cancel-button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s; // Transición para el cambio de color
}

.save-button {
    background-color: $colorCeleste;
    color: white;
    margin: 5px;
}

.save-button:hover {
    background-color: darken($colorCeleste, 10%); // Efecto hover más oscuro
}

.cancel-button {
    background-color: #f0f0f0; // Color para el botón de cancelar
    color: $colorNegro;
    margin: 5px;
}

.cancel-button:hover {
    background-color: #e0e0e0; // Efecto hover más oscuro
}

.change-name-button,
.delete-button-device {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s; // Transición para el cambio de color
}

.change-name-button {
    background-color: $colorCeleste;
    color: white;
}

.change-name-button:hover {
    background-color: darken($colorCeleste, 10%); // Efecto hover más oscuro
}

.delete-button-device {
    background-color: $colorRojo;
    color: white;
}

.delete-button-device:hover {
    background-color: darken($colorRojo, 10%); // Efecto hover más oscuro
}

.confirm-delete-popup {
    position: fixed; /* Fijo en la pantalla */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centra el popup */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Asegúrate de que se muestre sobre otros elementos */
    text-align: center;
}

.confirm-button {
    background-color: $colorRojo; /* Cambia según tus variables */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}

.confirm-button:hover {
    background-color: darken($colorRojo, 10%);
}

.confirm-delete-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

