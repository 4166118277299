@import '../../assets/_variables.scss';

.docs-page {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #222;
}

h2 {
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
}

h3 {
  font-size: 1.75rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #555;
}

.index-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.index-buttons a {
  padding: 10px 15px;
  font-size: 1rem;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}

.index-buttons a:hover {
  background-color: #0056b3;
}

.platform-selector {
  display: flex;
  gap: 10px;
  margin: 20px 0;
}

.platform-selector button {
  padding: 10px 15px;
  font-size: 0.9rem;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.platform-selector button.active {
  background-color: #007BFF;
  color: #fff;
}

.platform-selector button:hover {
  background-color: #007BFF;
  color: #fff;
}

pre {
  background-color: #f4f4f4;
  border-left: 4px solid #007BFF;
  padding: 15px;
  overflow-x: auto;
  border-radius: 5px;
}

pre.code-block {
  background-color: #1e1e1e;
  color: #c5c5c5;
  border-left: none;
  border-radius: 8px;
}

pre.code-block code {
  display: block;
  white-space: pre-wrap;
  line-height: 1.5;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

code {
  padding: 3px 6px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
}