

.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .success-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

    h2 {
        color: #4caf50;
        margin-bottom: 10px;
    }
    
    p {
        font-size: 16px;
    }
  }
  
  
  