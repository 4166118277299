@import '../../assets/_variables.scss';


.confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    .popup-content {
      background-color: $colorBlanco;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
  
      p {
        font-size: 16px;
        color: $colorNegro;
        margin-bottom: 20px;
      }
  
      .popup-actions {
        display: flex;
        gap: 10px;
        justify-content: center;
  
        .confirm-btn {
          background-color: $colorRojo;
          color: $colorBlanco;
          border: none;
          padding: 8px 12px;
          border-radius: 4px;
          cursor: pointer;
  
          &:hover {
            background-color: darken($colorRojo, 10%);
          }
        }
  
        .cancel-btn {
          background-color: $colorGris;
          color: $colorBlanco;
          border: none;
          padding: 8px 12px;
          border-radius: 4px;
          cursor: pointer;
  
          &:hover {
            background-color: darken($colorGris, 10%);
          }
        }
      }
    }
  }
  