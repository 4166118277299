@import '../../assets/_variables.scss';

.graph-switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; // Espacio entre el texto y el switch
  }
  
  .switch-label {
    font-size: 2rem; // Texto grande
    font-weight: bold; // Texto grueso para "ON" y "OFF"
    color: #333; // Color oscuro para buen contraste
  }
  
  