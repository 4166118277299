@import '../../assets/_variables.scss';

.terms-page {
  padding: 40px;
  background-color: #f9f9f9;

  .terms-header {
    text-align: center;
    margin-bottom: 30px;
    h1 {
      font-size: 36px;
      color: #333;
    }
  }

  .terms-content {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 28px;
      color: #333;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 22px;
      color: #555;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #666;
      line-height: 1.6;
      margin-bottom: 15px;
    }
  }
}
