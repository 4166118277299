@import '../../assets/_variables.scss';

.btn{
    padding: 5px 10px;
    background-color: $colorBlanco;
    border-radius: 5px;
    border: 1px solid $colorNegro25;
    margin: 10px;
    color: $colorNegro;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.25);
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover{
        border: 1px solid $colorNegro;
    }
}