@import './assets/_variables.scss';

*{
  margin: 0;
  padding: 0;
  font-family: "Kanit", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $colorBlanco;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $colorNegro25;
  border-radius: 10px;       
  border: 3px solid $colorBlanco;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
