@import '../../assets/_variables.scss';

.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .register-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 800px;
  }
  
  .logo {
    font-size: 36px;
    font-weight: bold;
    color: #0066cc;
  }
  
  .plan-type {
    margin-top: 10px;
    font-size: 24px;
    color: #333;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  
    input,
    select {
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
    }
  
    .submit-btn-free {
      margin-top: 10px;
      background-color: #0066cc;
      color: white;
      padding: 10px;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #004999;
      }
    }
  }

  .steps-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    font-size: 18px;
    color: white;
  }
  
  .step.active {
    background-color: #007bff; /* Color azul cuando el paso está activo */
  }
  
  .step-content {
    margin-top: 20px;
  }
  
  .next-btn, .prev-btn, .submit-btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .prev-btn {
    background-color: #6c757d; /* Gris para el botón anterior */
  }
  
  .prev-btn:hover {
    background-color: #5a6268;
  }
  
  .next-btn:hover, .submit-btn:hover {
    background-color: #0056b3;
  }
  
  .google-signin-btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #4285f4; /* Color típico de Google */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
  }
  
  .google-signin-btn:hover {
    background-color: #357ae8;
  }
  
  