@import '../../assets/_variables.scss';

.graph-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.graph-list__label {
    font-size: 14px;
    color: #333;
    font-weight: 500;
}

.graph-list__select {
    padding: 10px 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        outline: none;
    }
}